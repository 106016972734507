// Configuration
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/utilities';

// Layout & components
@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
@import '../node_modules/bootstrap/scss/progress';

// Helpers
@import '../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../node_modules/bootstrap/scss/utilities/api';

html {
  scroll-behavior: auto !important;
}

body {
  color: #ffffff;
  background-color: #001a33;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 45px;
}

h1,
h2,
h3 {
  font-weight: 300;
}

a,
a:hover {
  color: #ffffff;
  text-decoration: none;
}

.top-rule {
  margin-top: 0;
  margin-bottom: 25px;
}

.holiday-selection h2 {
  margin-top: 0.5rem;
  padding: 10px 0px;
}

.holiday-selection:hover,
.holiday-selection:hover > a {
  color: #001a33 !important;
  background-color: #ffffff;
  font-weight: 300;
}

.navbar {
  color: #ffffff;
  background-color: #001a33;
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.xdaysuntil-brand {
  font-weight: 400 !important;
}

.date {
  padding-bottom: 20px;
}

.btn {
  font-weight: 300;
}

.btn-more-countdowns,
.btn-share {
  color: #ffffff;
  border: 0.5px solid #ffffff;
  border-radius: 0px;
  width: 100%;
}

.btn-share {
  margin-bottom: 8px;
}

.btn-more-countdowns:hover,
.btn-share:hover {
  color: #001a33;
  background-color: #ffffff;
  border: 0.5px solid #001a33;
}

.holiday-summary {
  white-space: pre-line;
}

.more-countdowns-container {
  padding-bottom: 45px;
}

.progress-row {
  padding-bottom: 20px;
}

.progress {
  height: 0.2rem;
  background-color: #444444;
  border-radius: 0px;
}

.progress-bar {
  color: #444444;
  background-color: #ffffff;
}

.counter-label {
  font-size: 1.2rem;
}

.countdown-container {
  padding-top: 35px;
  padding-bottom: 35px;
}
