.dang {
  height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;

  .content {
    width: 100%;
    text-align: center;
  }
}
